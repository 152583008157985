.popup {
    position:fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-width: 320px;
    height: 1200vh;
    background-color:rgba(196, 196, 196, 0.2);
    display:-ms-grid;
    justify-content: center;
    align-items: center;
    max-height:100%;
    z-index: 1;
    /* -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px); */
}

.popup-inner {
    position: relative;
    top: 20px;
    padding:32px 4px;
    width: 100%;
    max-width: 800px;
    /* background-color:#FFF; */

    /* border-width:1px;  
    border-style:solid;
    border-color: #d096ff; */
    max-height:100%;
    background-attachment: fixed;
    /* background-color: #1f1f24;
    background-image: linear-gradient(147deg,rgba(0,154,207,.24) 2%,rgba(149,98,254,.24) 12%,rgba(31,31,36,.24) 30%)
     */
     /* -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px); */
    border: solid 2px #000;
    background-color: rgba(255, 255, 255, 0.65);
}

.popup-inner::after {
    position: absolute;
    inset: 0;
    content: '';
     -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    z-index: -1;
}

.popup-inner .close-btn {
    position: absolute;
    top: 100px;
    right: 25px;
}

.popup-inner .close {
    position: absolute;
    top: 10px;
    right: 15px;
    color: white;
}